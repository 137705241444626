import React from 'react';
import Section from '../../components/section';
import { PageHero } from '../../components/page-hero';
import Layout from '../../components/layout/layout';
import Tabs from '../../components/tabs';
import graphic from '../../img/jobs/designer.png';
import nodejs from '../../img/jobs/nodejs.svg';
import python from '../../img/jobs/python.svg';
import youtube from '../../img/jobs/youtube.png';
import reactLogo from '../../img/jobs/react.svg';
import { ToolCard } from '../../components/tool-card';
import { navigate } from '@reach/router';
import { Translate } from '../../components/translations';

const Career = () => {
  const jobs = [
    {
      logo: `${reactLogo}`,
      title: <Translate name='CAREER_REACT_JS_TITLE' /> ,
      route: '/career/mid-senior-reactjs',
    },
    {
      logo: `${youtube}`,
      title: <Translate name='CAREER_YOUTUBER_TITLE' /> ,
      route: '/career/hexometer-youtuber',
    },
    {
      logo: `${nodejs}`,
      title: <Translate name='CAREER_SENIOR_NODE_JS_TITLE' />,
      route: '/career/senior-nodejs',
    },
    {
      logo: `${nodejs}`,
      title: <Translate name='CAREER_MID_NODE_JS_TITLE' />,
      route: '/career/mid-nodejs',
    },
    {
      logo: `${python}`,
      title: <Translate name='CAREER_MID_PYTHON_TITLE' />,
      route: '/career/mid-python',
    },
    {
      logo: `${graphic}`,
      title: <Translate name='CAREER_GRAPHIC_DESIGNER_TITLE' /> ,
      route: '/career/graphic-designer',
    },
  ];

  return (
    <Layout>
      <PageHero
        title="CAREER_PAGE_TITLE"
        subtitle="CAREER_PAGE_SUBTITLE"
      />
      <Section about>
        <Tabs />
        <div style={{width: '95%'}}>
          {jobs.length > 0 ? jobs.map((job) => {
            return(
            <ToolCard
              key={job.route}
              image={<img src={job.logo} alt='vacancy' />}
              title={job.title}
              actionHref={job.route}
              actionText='Learn More'
              onClick={(() => navigate(`${job.route}`))}
            />)}) : <div className='text-center'><Translate name='CAREER_NO_VACANCIES' /></div>} 
        </div>
      </Section>
    </Layout>
  );
};

export default Career;
